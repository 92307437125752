<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			showOrder
			:activeTab="activeTab"
			custom
		/>
		<!-- 新增 -->
		<el-dialog
			:title="dialogTitle"
			width="900px"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="formItems"
				@submit="this.submitForm"
				@cancel="dialogVisible = false"
				:defaultData="formModel"
			>
			</formItem>
			<!-- <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitAddPlan">确 定</el-button>
			</span> -->
		</el-dialog>
	</div>
</template>

<script>
/* 评估师认定 */
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
export default {
	name: 'allEvaluation',
	components: {
		baseTable,
		formItem
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		appraiserLevel() {
			return this.$store.state.app?.dict?.filter((item) => item.groupId === 'appraiserLevel');
		},
		otherBtns() {
			return [
				{
					icon: 'el-icon-plus',
					label: '新增',
					click: () => {
						this.formModel = {};
						this.dialogVisible = true;
						this.dialogTitle = '新增';
					}
				}
			].filter((v) => v);
		},
		transfromLevel() {
			let str = '';

			return (level) => {
				switch (level) {
					case 1:
						str = '一';
						break;
					case 2:
						str = '二';
						break;
					case 3:
						str = '三';
						break;

					default:
						break;
				}
				return str ? `${str}级评估师` : '';
			};
		}
	},
	data() {
		return {
			dialogTitle: '新增',
			tableCols: [
				{
					label: '姓名',
					key: 'name'
				},
				{
					label: '工号',
					key: 'jobNumber'
				},
				{
					label: '手机号',
					key: 'phoneNumber'
				},
				{
					label: '评估师等级',
					key: 'qualificationLevel',
					formatter: (level) => this.transfromLevel(level)
				},
				{
					label: '权限',
					key: 'qx',
					formatter: (_, { qualificationLevel }) =>
						this.appraiserLevel.find(({ dictId }) => Number(dictId) === qualificationLevel)?.dictName
				},
				{
					label: '状态',
					key: 'status',
					formatter: (status) => (status === 0 ? '已注销' : '已启用')
				},
				{
					label: '操作',
					key: 'cz',
					width: '180px',
					fixed: 'right',
					render: (row) => (
						<div style="display:inline-flex;align-items: center;height: 22px">
							{this.permissionControlBtns(this.pageName, 'Edit') && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && row.status === 1 && (
								<el-button type="text" style={'color: #999'} onclick={() => this.handleCancellation(row, '0')}>
									注销
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && row.status === 0 && (
								<el-button type="text" style={'color: #67C23A'} onclick={() => this.handleCancellation(row, '1')}>
									启用
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && (
								<el-button type="text" style={'color:rgb(245, 108, 108); '} onclick={() => this.handleDel(row)}>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			queryItem: [
				{
					label: '姓名',
					key: 'name',
					type: 'input',
					span: 6
				},
				{
					label: '评估师等级',
					key: 'qualificationLevel',
					type: 'select',
					groupId: 'appraiserLevel',
					formatOpts: ({ dictId }) => ({ dictId, dictName: this.transfromLevel(Number(dictId)) }),
					span: 6
				}
			],
			formModel: {},
			dialogVisible: false,
			formItems: [
				{
					label: '姓名',
					key: 'staffId',
					showKey: 'name',
					type: 'selectSearch',
					span: 12,
					firstRequest: true, // 开始直接请求一次
					onlyOnceRequest: true, // 只请求一次 防止多个重复请求
					async: (keyWork) =>
						this.$axios
							.post('/v1/org/department/staff/list', { request: { keyWork, pageNo: 1, pageSize: 40 } })
							.then((res) => res?.list?.map(({ staffId, staffName }) => ({ dictId: staffId, dictName: staffName }))),
					rules: { required: true, message: '请选择', trigger: 'change' }
				},
				{
					label: '等级',
					key: 'qualificationLevel',
					type: 'select',
					groupId: 'appraiserLevel',
					formatOpts: ({ dictId }) => ({ dictId, dictName: this.transfromLevel(Number(dictId)) }),
					rules: { required: true, message: '请选择', trigger: 'change' },
					span: 12
				}
			]
		};
	},
	created() {},
	mounted() {
		// getApprovalTypeOnce();
	},
	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/internal/talent/getAppraiserList', params));
		},
		handleEdit(row, e) {
			e.stopPropagation();
			this.formModel = { ...row, qualificationLevel: row.qualificationLevel.toString() };
			this.dialogTitle = '编辑';
			this.formType = 'edit';
			this.dialogVisible = true;
		},
		// 删除
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios
						.post('/v1/internal/talent/deleteAppraiser', {
							request: { id: row.id }
						})
						.then((res) => {
							if (res && res.code === 0) {
								this.$message.success('删除成功!');
								this.$refs.baseTable?.handleSearch();
							} else {
								// this.$message.error(res.message)
							}
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 注销
		handleCancellation(row, status) {
			this.$confirm(status === '0' ? '是否注销?' : '是否启用？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios
						.post('/v1/internal/talent/cancelAppraiser', {
							request: { id: row.id, status }
						})
						.then((res) => {
							if (res && res.code === 0) {
								const msg = status === '0' ? '注销成功' : '启用成功';

								this.$message.success(msg);
								this.$refs.baseTable?.handleSearch();
							} else {
								// this.$message.error(res.message)
							}
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post('/v1/internal/talent/saveAppraiser', {
							request: { ...this.formModel }
						})
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success(this.formType === 'edit' ? '编辑成功' : '新增成功');
							this.dialogModel = {};
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>
