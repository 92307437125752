<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane :lazy="true" label="评估师认定" name="1" v-if="permissionControl('AppraiserIdentification')"
				><AppraiserIdentification ref="pane1" pageName="AppraiserIdentification"></AppraiserIdentification
			></el-tab-pane>
			<!-- <el-tab-pane :lazy="true" label="数据统计" name="2" v-if="permissionControl('AssessmentDataStatistics')"
				><AppraiserIdentification ref="pane2" pageName="AssessmentDataStatistics"></AppraiserIdentification
			></el-tab-pane> -->
		</el-tabs>
	</div>
</template>

<script>
import AppraiserIdentification from './appraiserIdentification.vue'; // 评估列表

// import * as api from "@/api/workbench";
export default {
	name: 'AppraiserManagement',
	components: {
		AppraiserIdentification
	},
	data() {
		return {
			activeName: '1',
			listQuery: {}
		};
	},

	computed: {},
	created() {},
	methods: {},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		const { activeName, listQuery } = data || {};

		if (activeName) {
			this.activeName = activeName;
		}
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'AppraiserIdentification' },
			{ name: '2', key: 'AssessmentDataStatistics' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`pane${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>
